import React, { Component, Fragment } from 'react'
import { AButton } from 'shared/components/common'
import { BillingInfoAddProps, BillingInfoAddState } from './types'
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Spinner, Alert } from 'reactstrap'
import _ from 'lodash'

import Formsy, { addValidationRule } from 'formsy-react'
import Container from 'reactstrap/lib/Container'
import { InputFormsy, TextAreaFormsy, SweetSelectFormsy, SelectFormsy } from 'shared/components/formsy'
import { validRFC } from 'core/utils'
import { InvoiceService } from 'modules/invoice/services'
import Address from 'shared/models/Address'
import { ApiErrors } from 'core/services/ApiService/types'
import { GenericService } from 'core/services'
import { taxRegimens } from '../EmailCapture/taxRegimens'

addValidationRule('isRfc', (_values: any, value: string) => {
  return value && validRFC(value.trim().toUpperCase())
})

addValidationRule('isEnterpriseName', (_values: any, value: string) => {
  return value && /[^\|]/g.test(value)
})

addValidationRule('isStreetNumber', (_values: any, value: string) => {
  if (!value) {
    return true
  }
  return value && /([0-9]){1,15}|^([sS]\/[nN])$/.test(value)
})

export class BillingInfoAdd extends Component<BillingInfoAddProps, BillingInfoAddState> {
  form: any
  countryField: any
  stateField: any
  countyField: any
  cityField: any
  suburbField: any
  streetField: any
  legalField: any
  taxRegimenField: any
  cpField: any
  state: BillingInfoAddState = {
    countries: [{ id: 1, name: 'México' }],
    states: [],
    counties: [],
    cities: [],
    suburbs: []
  }
  availableTaxRegimens: any = []

  constructor(props: BillingInfoAddProps) {
    super(props)
    this.state.selectedCountry = this.state.countries[0].id
    this.searchZipCode = _.debounce(this.searchZipCode, 500)
  }

  toggle = () => {
    const value = !this.state.modal
    if (value) {
      if (this.form) {
        this.form.reset()
      }
      this.setState(state => ({
        selectedCountry: state.countries[0].id,
        selectedState: 0,
        selectedCounty: 0
      }))
    }
    this.setState({
      modal: value
    })
  }

  searchZipCode = (zipCode: string) => {
    if (zipCode.length !== 5) {
      return
    }
    if (!this.cpField.isValid()) {
      return
    }
    this.setState({ searching: true, error: '' })
    let newState: any = { searching: false }
    InvoiceService.searchByZipCode(zipCode)
      .then(addresses => {
        if (!addresses) {
          throw {
            status: ApiErrors.HANDLED_ERROR,
            message: 'No se encontraron resultados para ese código postal'
          }
        }
        const selectedCountry = addresses.countries[0]
        const selectedState = addresses.states[0]
        const selectedCounty = addresses.counties[0]
        const selectedCity = addresses.cities[0]
        const selectedSuburb = addresses.suburbs[0]

        newState = {
          ...newState,
          ...addresses,
          selectedCountry: selectedCountry.id,
          selectedState: selectedState.id,
          selectedCounty: selectedCounty.id
        }
        this.setState(newState)
        this.countryField.setValue(selectedCountry)
        this.stateField.setValue(selectedState)
        this.countyField.setValue(selectedCounty)
        this.cityField.setValue(selectedCity)
        this.suburbField.setValue(selectedSuburb)
      })
      .catch(err => {
        newState.error = this.getErrorMessage(err)
        this.setState(newState)
      })
  }

  onSubmit = (model: any) => {
    const address = new Address({
      zipCode: model.zipCode,
      no_ext: model.no_ext,
      no_int: model.no_int,
      street: model.street,
      suburb: model.suburb.name
    })
    this.setState({ searching: true, error: '' })
    const newState: any = { searching: false }
    if (model.legal_person === 'Persona física') {
      model.legal_person = 'fisico'
    } else if (model.legal_person === 'Persona moral') {
      model.legal_person = 'moral'
    }

    return GenericService.create<any>(`streets`, { county_id: model.county.id, name: model.street })
      .then((newStreet: any) => {
        const { user } = this.props
        if (!user.customer) {
          return Promise.reject()
        }
        return InvoiceService.createBillingInfo({
          zip_code: model.zipCode,
          no_ext: model.no_ext,
          no_int: model.no_int,
          street_id: newStreet.id,
          suburb_id: model.suburb.id,
          address: address.toString(),
          country_id: model.country.id,
          city_id: model.city.id,
          county_id: model.county.id,
          state_id: model.state.id,
          rfc: model.rfc,
          customer_id: user.customer.id,
          legal_person: model.legal_person,
          name: model.name,
          tax_regimen: model.tax_regimen
        })
      })
      .then(() => {
        this.setState(newState)
        if (this.props.onAdd) {
          this.props.onAdd()
        }
        this.toggle()
      })
      .catch(err => {
        console.error(err)
        newState.error = this.getErrorMessage(err)
        this.setState(newState)
      })
  }

  getErrorMessage = (err: any) => {
    if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
      return err.message
    }
    return 'Error al registrar datos de facturación. Intente de nuevo más tarde.'
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }

  assignFilter = (val: any, key: string) => {
    const newState: any = {}
    const value = val.id
    if (key === 'country') {
      newState.selectedCountry = value
    }

    if (key === 'state') {
      newState.selectedState = value
    }

    if (key === 'county') {
      newState.selectedCounty = value
    }

    this.resetValues(key)

    this.setState(newState)
  }

  resetValues = (key: string) => {
    if (key === 'country') {
      this.stateField.resetValue()
      this.assignFilter({ id: '' }, 'state')
      this.countyField.resetValue()
      this.assignFilter({ id: '' }, 'county')
    }

    if (key === 'state') {
      this.countyField.resetValue()
      this.assignFilter({ id: '' }, 'county')
    }

    this.cityField.resetValue()
    this.suburbField.resetValue()
    this.streetField.resetValue()
  }

  handleLegalPerson = (rfc: string) => {
    if (rfc.length === 12) {
      this.legalField.setValue('Persona moral')
      this.handleTaxRegimens('moral')
    } else if (rfc.length === 13) {
      this.legalField.setValue('Persona física')
      this.handleTaxRegimens('fisica')
    } else {
      this.legalField.resetValue()
    }
  }

  handleTaxRegimens(type: string) {
    this.taxRegimenField.resetValue()
    if (type) {
      this.availableTaxRegimens = taxRegimens.filter((t: any) => t[type])
    }
    return []
  }

  render() {
    const {
      canSubmit,
      selectedCountry,
      selectedCounty,
      selectedState,
      searching,
      error,
      countries,
      states,
      counties,
      cities,
      suburbs
    } = this.state
    return (
      <Fragment>
        <AButton variant="med" onClick={this.toggle} className="font-weight-bold text-bold" style={{ width: 50, borderRadius: '50%' }}>
          <i className="fa fa-plus" />
        </AButton>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" centered backdrop="static">
          <Formsy
            ref={(node: any) => {
              this.form = node
            }}
            onValidSubmit={this.onSubmit}
            onValid={this.onValidForm}
            onInvalid={this.onInvalidForm}
          >
            <ModalHeader>
              <label className="bluep h6">
                <i className="fas fa-address-book" style={{ paddingRight: 10 }} /> Agregar datos de facturación
              </label>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col sm={12}>
                    <InputFormsy
                      name="name"
                      label="Razón social:"
                      inputProps={{ placeholder: 'Escribe tu razón social', disabled: searching, maxLength: 250, uppercase: true }}
                      validations={{ isEnterpriseName: true }}
                      validationErrors={{ isEnterpriseName: 'El texto introducido no es una razón social válida' }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <InputFormsy
                      name="rfc"
                      label="RFC:"
                      onChange={this.handleLegalPerson}
                      inputProps={{ placeholder: 'Escribe tu RFC', maxLength: 13, disabled: searching, uppercase: true }}
                      validations={{ isRfc: true }}
                      validationErrors={{ isRfc: 'El texto introducido no es un RFC válido' }}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <InputFormsy
                      name="legal_person"
                      label="Persona jurídica"
                      value=""
                      ref={(node: any) => {
                        this.legalField = node
                      }}
                      inputProps={{ disabled: searching, readOnly: true }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <SelectFormsy
                      name="tax_regimen"
                      label="Régimen fiscal"
                      ref={(node: any) => {
                        this.taxRegimenField = node
                      }}
                      selectProps={{
                        disabled: searching
                      }}
                      value=""
                      required
                    >
                      <option value="">Selecciona el régimen fiscal</option>
                      {this.availableTaxRegimens.map((u: any) => (
                        <option key={u.code} value={u.code}>
                          {u.description}
                        </option>
                      ))}
                    </SelectFormsy>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <InputFormsy
                      name="zipCode"
                      label="Código Postal:"
                      onChange={this.searchZipCode}
                      ref={(node: any) => {
                        this.cpField = node
                      }}
                      inputProps={{ placeholder: 'Escribe tu código postal', maxLength: 5, disabled: searching }}
                      validations={{
                        isNumeric: true,
                        minLength: 5
                      }}
                      validationErrors={{
                        isNumeric: 'El número introducido no es un código postal válido',
                        minLength: 'El número introducido no tiene una longitud válida'
                      }}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <SweetSelectFormsy
                      label="País"
                      name="country"
                      onChange={(val: any) => this.assignFilter(val, 'country')}
                      value={countries[0]}
                      ref={(node: any) => {
                        this.countryField = node
                      }}
                      selectProps={{
                        model: 'countries',
                        searchKeys: 'name*',
                        isDisabled: searching,
                        placeholder: 'Seleccionar país',
                        getOptionLabel: (d: any) => d.name,
                        getOptionValue: (d: any) => d.id,
                        defaultOptions: countries
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <SweetSelectFormsy
                      label="Estado"
                      name="state"
                      onChange={(val: any) => this.assignFilter(val, 'state')}
                      ref={(node: any) => {
                        this.stateField = node
                      }}
                      selectProps={{
                        model: 'states',
                        limit: 32,
                        searchKeys: 'name*',
                        isDisabled: !Boolean(selectedCountry) || searching,
                        placeholder: 'Seleccionar estado',
                        getOptionLabel: (d: any) => d.name,
                        getOptionValue: (d: any) => d.id,
                        defaultOptions: states.length ? states : true,
                        cacheOptions: true,
                        fetchQuery: {
                          orderBy: 'name',
                          where: selectedCountry ? `country_id='${selectedCountry}'` : ''
                        }
                      }}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <SweetSelectFormsy
                      label="Municipio"
                      name="county"
                      onChange={(val: any) => this.assignFilter(val, 'county')}
                      ref={(node: any) => {
                        this.countyField = node
                      }}
                      selectProps={{
                        model: 'counties',
                        searchKeys: 'name*',
                        isDisabled: !Boolean(selectedState) || searching,
                        fetchQuery: selectedState ? { where: `state_id='${selectedState}'` } : null,
                        placeholder: 'Seleccionar municipio',
                        getOptionLabel: (d: any) => d.name,
                        getOptionValue: (d: any) => d.id,
                        defaultOptions: counties
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <SweetSelectFormsy
                      label="Ciudad"
                      name="city"
                      ref={(node: any) => {
                        this.cityField = node
                      }}
                      selectProps={{
                        model: 'cities',
                        searchKeys: 'name*',
                        isDisabled: !Boolean(selectedCounty) || searching,
                        fetchQuery: selectedCounty ? { where: `county_id='${selectedCounty}'` } : null,
                        placeholder: 'Seleccionar ciudad',
                        getOptionLabel: (d: any) => d.name,
                        getOptionValue: (d: any) => d.id,
                        defaultOptions: cities
                      }}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <SweetSelectFormsy
                      label="Colonia"
                      name="suburb"
                      ref={(node: any) => {
                        this.suburbField = node
                      }}
                      selectProps={{
                        model: 'suburbs',
                        searchKeys: 'name*',
                        isDisabled: !Boolean(selectedCounty) || searching,
                        fetchQuery: selectedCounty ? { where: `county_id='${selectedCounty}'` } : null,
                        placeholder: 'Seleccionar colonia',
                        getOptionLabel: (d: any) => d.name,
                        getOptionValue: (d: any) => d.id,
                        defaultOptions: suburbs
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <InputFormsy
                      name="street"
                      label="Calle:"
                      inputProps={{ placeholder: 'Escribe tu calle', disabled: searching, maxLength: 100 }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <InputFormsy
                      name="no_ext"
                      label="Número exterior:"
                      inputProps={{ placeholder: 'Escribe tu número exterior', maxLength: 10, disabled: searching }}
                      validations={{ isStreetNumber: true }}
                      validationErrors={{ isStreetNumber: 'El número exterior introducido no es válido' }}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <InputFormsy
                      name="no_int"
                      label="Número interior:"
                      inputProps={{ placeholder: 'Escribe tu número interior', maxLength: 10, disabled: searching }}
                      validations={{ isStreetNumber: true }}
                      validationErrors={{ isStreetNumber: 'El número interior introducido no es válido' }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <TextAreaFormsy
                      name="reference"
                      label="Referencia:"
                      inputProps={{ placeholder: 'Escribe algúna referencia del domicilio', rows: 5, disabled: searching }}
                    />
                  </Col>
                </Row>
                {searching ? (
                  <Row>
                    <Col sm={12} className="text-center">
                      <Spinner color="danger" />
                    </Col>
                  </Row>
                ) : null}
                {error ? (
                  <Row>
                    <Col sm={12} className="text-center">
                      <Alert color="danger">{error}</Alert>
                    </Col>
                  </Row>
                ) : null}
              </Container>
            </ModalBody>
            <ModalFooter>
              <AButton type="submit" variant="pink" disabled={!canSubmit}>
                Agregar
              </AButton>
              <AButton variant="gray" onClick={this.toggle} disabled={searching}>
                Cancelar
              </AButton>
            </ModalFooter>
          </Formsy>
        </Modal>
      </Fragment>
    )
  }
}

export default BillingInfoAdd
