import React, { Component, Fragment, SFC } from 'react'
import { BillingInfoListHeader, BillingInfoListData, BillingInfoListProps, BillingInfoListState } from './types'
import Row from 'reactstrap/lib/Row'
import { Col, Spinner } from 'reactstrap'
import classNames from 'classnames'
import BillingInfoAdd from '../BillingInfoAdd/BillingInfoAdd'
import EmailCapture from '../EmailCapture/EmailCapture'
import { InvoiceType } from 'modules/invoice/models/Invoice'

const Header: SFC<BillingInfoListHeader> = ({ header, index }) => (
  <Col sm={index ? 2 : 3}>
    <h5 className="big-blue">{header}</h5>
  </Col>
)

const Item: SFC<BillingInfoListData> = ({
  data,
  onSelect,
  name,
  zip_code,
  legal_person,
  tax_regimen,
  defaultEmail,
  isParcel,
  isGuiapp
}) => (
  <Row className="mt-4">
    <Col sm={3}>
      <h5 className="big-gray">{data.name}</h5>
    </Col>
    <Col sm={2}>
      <h5 className="big-gray">{data.rfc.toUpperCase()}</h5>
    </Col>
    <Col sm={4}>
      <h5 className="big-gray">{data.address}</h5>
    </Col>
    <Col sm={3}>
      <EmailCapture
        isParcel={isParcel}
        isGuiapp={isGuiapp}
        default={defaultEmail}
        name={name}
        zip_code={zip_code}
        legal_person={legal_person}
        tax_regimen={tax_regimen}
        onConfirm={(
          customerName: string,
          customerZipCode: string,
          customerTaxRegimen: string,
          email: string,
          cfdiUse: string,
          type?: InvoiceType
        ) => {
          if (onSelect) {
            onSelect(data, customerName, customerZipCode, customerTaxRegimen, email, cfdiUse, type)
          }
        }}
      />
    </Col>
  </Row>
)

const EmptyTemplate: SFC<{ isRegistered: boolean }> = ({ isRegistered }) => (
  <Row>
    <Col sm={12} className="text-center">
      <h5 className="big-gray">{isRegistered ? 'No tiene datos de facturación registrados' : 'Busca tus datos de facturación'}</h5>
    </Col>
  </Row>
)

export class BillingInfoList extends Component<BillingInfoListProps, BillingInfoListState> {
  render() {
    const headers = ['Razón Social', 'R.F.C.', 'Dirección']
    const { data, onSelectData, loading, user, type } = this.props
    return (
      <Fragment>
        <Row className={classNames('mb-4', { 'mt-5': !user })}>
          <Col sm={8}>
            <h3 className="big-blue">Elija sus datos de facturación</h3>
            <br />
          </Col>
          <Col sm={4} className="text-right">
            {user ? <BillingInfoAdd user={user} onAdd={this.props.onAdd} /> : null}
            <br />
          </Col>
        </Row>
        {loading ? (
          <div className="w-100 text-center">
            <Spinner color="danger" />
          </div>
        ) : data.length ? (
          <Fragment>
            <Row>
              {headers.map((h, i) => (
                <Header key={h} index={i} header={h} />
              ))}
            </Row>
            {data.map(d => (
              <Item
                key={d.id}
                defaultEmail={(user && user.email) || ''}
                name={(d && d.name) || ''}
                zip_code={d.zip_code}
                tax_regimen={(d && d.tax_regimen) || ''}
                legal_person={(d && d.person) || ''}
                data={d}
                onSelect={onSelectData}
                isParcel={type === 'parcel'}
                isGuiapp={type === 'guia_pp'}
              />
            ))}
          </Fragment>
        ) : (
          <EmptyTemplate isRegistered={!!user} />
        )}
      </Fragment>
    )
  }
}

export default BillingInfoList
