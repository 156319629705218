import React, { Component, Fragment } from 'react'
import { ABanner, ATabs, AButton, Go } from 'shared/components/common'
import { Container, Row, Col, ModalHeader, ModalBody, Modal, ModalFooter } from 'reactstrap'
import tufactura from 'img/vectores/tufactura.svg'
import AllAbordoLogo from 'img/AllAbordoLogo.svg'
import { InvoiceProps, InvoiceState } from './types'
import { SearchService, ServiceInfo, BillingInfoList, BillingInfoSearch } from 'modules/invoice/components'
import { InvoiceService } from 'modules/invoice/services'
import { ABannerProps } from 'shared/components/common/ABanner/types'
import { Invoice } from 'modules/invoice/models'
import { ApiErrors } from 'core/services/ApiService/types'
import Alert from 'reactstrap/lib/Alert'
import { InvoiceBody, CFDIIngreso, ConceptoCFDI, BodyIngreso } from 'modules/invoice/services/InvoiceService/types'
import { Config } from 'core'
import { InvoiceType } from 'modules/invoice/models/Invoice'
import moment from 'moment'
import { Link } from 'gatsby'
import { MailService } from 'services'
import { getBillingEmailString } from './BillingEmailTemplate'
import { InputFormsy } from 'shared/components/formsy'
import Formsy from 'formsy-react'

const statusIcons: IndexSignature = {
  progress: 'fa-tasks text-info',
  error: 'fa-exclamation-triangle text-warning',
  expired: 'fa-exclamation-circle text-danger'
}

const statusLegend: IndexSignature = {
  progress:
    'Ha ocurrido un error con la generación de su factura, favor de comunicarse con soporte allAbordo al correo facturacion@allabordo.com',
  error:
    'Verifique que sus datos fiscales sean correctos, de persistir el problema, favor de comunicarse al correo facturacion@allabordo.com',
  expired: 'Su factura ha sido evaluada como no elegible, favor de generarla nuevamente'
}

export class InvoiceLayout extends Component<InvoiceProps, InvoiceState> {
  form: any
  tabRef = ATabs.createRef()
  state: InvoiceState = {
    billingData: [],
    collapse: false,
    isEmailModalOpen: false,
    clientEmail: ''
  }

  componentDidMount() {
    if (this.props.invoice) {
      this.refreshBillingDataList()
      this.tabRef.selectTab(1)
    }
  }

  refreshBillingDataList = () => {
    const { user } = this.props
    if (user && user.customer) {
      this.setState({ searching: true, invoiceError: '' })
      InvoiceService.getBillingDataList(user.customer.id)
        .then(datas => {
          this.setState({ billingData: datas, searching: false })
        })
        .catch(this.handleError)
    }
  }

  formaPago = (formaPago: String) => {
    let pago
    switch (formaPago) {
      case 'cash':
        pago = '01'
        break
      case 'card':
        pago = '04'
        break
      case 'debit':
        pago = '28'
        break
      case 'check':
        pago = '02'
        break
      case 'transfer':
        pago = '03'
        break
      case 'deposit':
        pago = '99'
        break
      default:
        pago = '99'
    }

    return pago
  }

  handleSearch = (code: string) => {
    const { setInvoiceData } = this.props
    this.setState({ searching: true, invoiceError: '' })
    const serviceID = code.charAt(0)
    let type
    switch (serviceID) {
      case 'G':
        type = 'parcel'
        break
      case 'B':
        type = 'boarding_pass'
        break
      case 'R':
        type = 'rental'
        break
      case 'P':
        type = 'guia_pp'
        break
      case 'M':
        type = 'e_wallet_recharge'
        break
      default:
        type = 'boarding_pass'
    }
    InvoiceService.getServiceDetail(code, type)
      .then(detail => {
        const tipo = detail.service
        if (
          tipo === 'Envío de paqueteria' ||
          tipo === 'Boletos de autobuses' ||
          tipo === 'Guias Prepagadas' ||
          tipo === 'Recarga de monedero electrónico'
        ) {
          setInvoiceData({ ...detail })
          this.setState({ searching: false })
          this.refreshBillingDataList()
        } else {
          detail.invoice_status = 'reload'
          setInvoiceData({ ...detail })
          this.setState({ searching: false })
        }
      })
      .catch(this.handleError)
  }

  handleBillingInfoSearch = (model: any) => {
    this.setState({ searching: true, invoiceError: '' })
    InvoiceService.getBillingData(model.rfc, model.zipCode)
      .then(data => {
        this.setState({ searching: false, billingData: data })
      })
      .catch(this.handleError)
  }

  handleSelect = (
    data: any,
    name: string,
    zip_code: string,
    tax_regimen: string,
    email: string,
    cfdiUse: string,
    capturedType?: InvoiceType
  ) => {
    const { invoice } = this.props
    if (invoice) {
      const { code, service_type } = invoice
      let type = service_type
      const { id } = data
      if (capturedType) {
        type = capturedType
      }
      this.props.setInvoiceData({ email, service_type: type })
      this.makeInvoice({
        email,
        code,
        type,
        name,
        zip_code,
        tax_regimen,
        cfdi_use: cfdiUse,
        billing_id: id
      })
    }
  }

  financial = (num: Number) => {
    return num.toFixed(2)
  }

  makeInvoice = (body: InvoiceBody) => {
    this.setState({ searching: true, invoiceError: '' })
    InvoiceService.requestInvoice(body)
      .then(data => {
        const fechaString = moment.utc(new Date()).toString()
        const dataByType = this.getInvoiceDataByType(data.type)

        const json: CFDIIngreso = {
          Serie: dataByType.serie,
          Version: '4.0',
          TranspInternac: 'No',
          TipoDeComprobante: 'I',
          Moneda: 'MXN',
          LugarExpedicion: data.parcel_info.terminal_zip_code.toString(),
          Fecha: fechaString.substring(0, fechaString.length - 5),
          Total: data.invoice.total_amount.toString(),
          Subtotal: this.financial(data.invoice.amount),
          Descuento: '0',
          Emisor: {},
          Receptor: {},
          Conceptos: [],
          FormaPago: this.formaPago(data.invoice.payment_method),
          PaisOrigenDestino: 'MEX',
          Impuestos: {}
        }
        const arrPackages = Array<ConceptoCFDI>()

        const objConcepto = {
          Cantidad: '1',
          ClaveProdServ: dataByType.serviceKey,
          Descripcion: `${dataByType.description} ${data.code}`,
          Descuento: '0',
          Importe: this.financial(data.invoice.amount),
          NoIdentificacion: data.code,
          Unidad: dataByType.unit,
          ValorUnitario: this.financial(data.invoice.amount),
          Impuestos: {
            Traslados: {
              Importe: this.financial(data.invoice.iva),
              TipoFactor: 'Tasa',
              TasaOCuota: `${data.parcel_info.branchIva}0000`,
              Impuesto: '002',
              Base: this.financial(data.invoice.amount)
            }
          }
        }
        arrPackages.push(objConcepto)
        json.Conceptos = arrPackages

        json.Emisor = {
          Rfc: dataByType.issuing?.rfc,
          RegimenFiscal: '601',
          Nombre: dataByType.issuing?.name
        }
        json.Receptor = {
          Rfc: data.billing.rfc,
          UsoCFDI: data.cfdi_use,
          Nombre: data.name,
          DomicilioFiscalReceptor: data.zip_code,
          RegimenFiscalReceptor: data.tax_regimen
        }

        const trasladoObj = {
          TotalImpuestosTrasladados: this.financial(data.invoice.iva),
          Traslado: {
            Base: this.financial(data.invoice.amount),
            Importe: this.financial(data.invoice.iva),
            TipoFactor: 'Tasa',
            TasaOCuota: `${data.parcel_info.branchIva}0000`,
            Impuesto: '002'
          }
        }
        json.Impuestos = trasladoObj

        const bodyIngreso: BodyIngreso = {
          cfdi_use: data.cfdi_use,
          cfdi: json,
          code: data.code,
          parcel_id: data.invoice.id,
          invoice: data.invoice,
          billing: data.billing,
          email: data.email,
          type: data.type,
          purchase_origin: data.parcel_info.purchase_origin
        }
        return bodyIngreso
      })
      .then(data => {
        InvoiceService.registerTimbox(data).then(result => {
          const d = result
          if (d.error) {
            const parser = new DOMParser()
            const xml = parser.parseFromString(d.error, 'application/xml')
            const errorNode = xml.getElementsByTagName('faultstring')[0]
            const errorMessage = errorNode.textContent
            this.props.setInvoiceData({ invoice_status: 'error', errorMessage: errorMessage ?? undefined })
            this.setState({ searching: false })
          } else {
            this.toggle()
            this.SendBillingLinkPdfToEmail(data.code, '', data.email)
            this.props.setInvoiceData({ invoice_status: 'done' })
            this.setState({ searching: false })
          }
        })
      })
      .catch(this.handleError)
  }

  clearInvoice = () => {
    const { removeInvoiceData } = this.props
    removeInvoiceData()
  }

  handleError = (err: any) => {
    const newState: any = { searching: false }
    newState.invoiceError = this.getErrorMessage(err)
    this.setState(newState)
  }

  getErrorMessage = (err: any) => {
    if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
      return err.message
    }
    if (err.error) {
      const devMsg = err.error.devMessage
      if (devMsg && devMsg === 'Service: not found') {
        return 'No se encontró el servicio especificado. Intente con otro código.'
      }
    }
    return 'Error al realizar la búsqueda. Intente de nuevo más tarde.'
  }

  handleNewData = () => {
    this.refreshBillingDataList()
  }
  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }))
  }
  reload = () => {
    this.props.setInvoiceData({ invoice_status: 'done' })
  }

  SendBillingLinkPdfToEmail = (code: string, clientName: string, email: string) => {
    this.setState({ ...this.state, isEmailModalOpen: false })
    MailService.sendEmail({
      subject: 'All Abordo | Factura Electronica',
      html_content: getBillingEmailString(document.location.origin, code, clientName),
      send_to: email
    })
  }

  handleSendEmail(code: string, clientName: string) {
    if (this.props.invoice && this.props.invoice.customer.email) {
      return this.SendBillingLinkPdfToEmail(code, clientName, this.props.invoice.customer.email)
    }
    this.setState({ code, isEmailModalOpen: true, client: clientName })
  }

  getEmailForm = (f: any) => {
    if (f) {
      this.form = f
      const { clientEmail = '' } = this.state
      this.form.reset({ email: clientEmail })
    }
  }

  onEmailSubmit = (model: any) => {
    this.setState({ ...this.state, isEmailModalOpen: false, clientEmail: model.email })
    const { code = '', client = '' } = this.state
    const { email } = model as { email: string }
    this.SendBillingLinkPdfToEmail(code, client, email)
  }

  onValidFormEmail = () => {
    this.setState({ canSubmitEmail: true })
  }

  onInvalidFormEmail = () => {
    this.setState({ canSubmitEmail: false })
  }

  getInvoiceDataByType = (type: String) => {
    switch (type) {
      case 'boarding_pass':
        return {
          serie: 'W',
          serviceKey: '78111802',
          description: 'SERVICIO DE VIAJE',
          unit: 'E48',
          issuing: {
            name: Config.rfcNameBoardingPass,
            rfc: Config.rfcBoardingPass
          }
        }
      case 'parcel':
        return {
          serie: 'W',
          serviceKey: '78102200',
          description: 'PAQUETERIA Y MENSAJERIA CODIGO',
          unit: 'E48',
          issuing: {
            name: Config.rfcNameParcel,
            rfc: Config.rfcParcel
          }
        }
      case 'guiapp':
        return {
          serie: 'W',
          serviceKey: '78102200',
          description: 'PAQUETE DE GUIAS PREPAGO CODIGO',
          unit: 'E48',
          issuing: {
            name: Config.rfcNameParcel,
            rfc: Config.rfcParcel
          }
        }
      case 'e_wallet_recharge':
        return {
          serie: 'ME',
          serviceKey: '84111506',
          description: 'ANTICIPO DEL BIEN O SERVICIO',
          unit: 'ACT',
          issuing: {
            name: Config.rfcNameBoardingPass,
            rfc: Config.rfcBoardingPass
          }
        }
      default:
        return {}
    }
  }

  render() {
    const { searching, billingData, invoiceError } = this.state
    const { invoice, user } = this.props

    const headerContent: ABannerProps = {
      title: 'Tu factura',
      titleClassName: 'big-white',
      content: <img src={tufactura} />,
      titleSize: 10
    }
    let errorMessage
    if (invoiceError) {
      errorMessage = (
        <Alert color="danger" className="d-inline-block">
          {invoiceError}
        </Alert>
      )
    }

    let BodyContent = (
      <Fragment>
        <Row>
          <Col sm={12}>
            <p>Realiza la búsqueda de tu servicio para continuar con el proceso de facturación.</p>
            <br />
          </Col>
        </Row>
        {errorMessage}
        <SearchService onConfirm={this.handleSearch} disabled={searching} />
      </Fragment>
    )

    if (invoice) {
      headerContent.title = `${invoice.service}\n${invoice.code}`
      headerContent.content = <ServiceInfo {...invoice} />
      headerContent.titleClassName = 'big-white-op'
      headerContent.titleSize = 4
      if (invoice.invoice_status === 'done' && invoice.media_document_pdf_name && invoice.media_document_xml_name) {
        BodyContent = (
          <Row>
            <Col sm={12} className="text-center">
              <br />
              <br />
              <br />
              <h4 className="big-blue">Tu factura ha sido enviada con éxito al correo:</h4>
              <h2 className="big-gray">{invoice.email}</h2>
              <br />
              <div className="btn-group">
                <a href={`$ Config.cfdi}/${invoice.media_document_pdf_name}`} target="blank">
                  <AButton variant="pink">Descargar pdf</AButton>
                </a>
                <a href={`$ Config.cfdi}/${invoice.media_document_xml_name}`} target="blank">
                  <AButton variant="pink">Descargar XML</AButton>
                </a>
              </div>
              <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Generar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'reload') {
        BodyContent = (
          <Row>
            <Col sm={12} className="text-center">
              <br />
              <br />
              <br />
              <h4 className="big-blue">Servicio no disponible para facturación</h4>
              <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Generar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'expired') {
        BodyContent = (
          <Row>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Estatus:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Buscar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'progress') {
        BodyContent = (
          <Row>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Estatus:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <Fragment>
                <br />
                <br />
                <AButton variant="pink" onClick={this.clearInvoice}>
                  Volver a solicitar factura
                </AButton>
              </Fragment>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'error') {
        BodyContent = (
          <Row>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Status:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <h4 className="big-blue">{invoice.errorMessage}</h4>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <Fragment>
                <br />
                <br />
                <AButton variant="pink" onClick={this.clearInvoice}>
                  Volver a solicitar factura
                </AButton>
              </Fragment>
            </Col>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status !== 'pending') {
        BodyContent = (
          <Row>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Estatus:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <Link to={`/imprimirfactura/?code=${this.props.invoice ? this.props.invoice.code : ''}`}>Imprimir factura</Link>{' '}
              <div className="mb-3" />
              <AButton
                variant="pink"
                onClick={() =>
                  this.handleSendEmail(
                    this.props.invoice ? this.props.invoice.code : '',
                    this.props.invoice ? `${this.props.invoice.customer.first_name} ${this.props.invoice.customer.last_name}` : ''
                  )
                }
              >
                Enviar enlace de factura a correo
              </AButton>{' '}
              <br /> <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Buscar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else {
        const billingList = billingData.map(d => {
          return {
            id: d.id,
            name: d.name,
            person: d.legal_person,
            rfc: d.rfc,
            address: d.address,
            zip_code: d.zip_code,
            tax_regimen: d.tax_regimen
          }
        })

        const ListComponent = (
          <Fragment>
            <BillingInfoList
              type={invoice.service_type}
              data={billingList}
              user={user}
              onAdd={this.handleNewData}
              onSelectData={this.handleSelect}
              loading={searching}
            />
            <br />
            <br />
            <div className="text-center">
              <AButton variant="med" onClick={this.clearInvoice}>
                Buscar otra factura
              </AButton>
            </div>
          </Fragment>
        )

        if (!user) {
          BodyContent = (
            <Fragment>
              <Row>
                <Col sm={12}>
                  <p>
                    Realiza la búsqueda de tus datos de facturación, en caso de que no tengas ninguno registrado te recomendamos
                    <Go to="iniciarsesion"> iniciar sesión</Go>.
                  </p>
                  <br />
                </Col>
              </Row>
              {errorMessage}
              <BillingInfoSearch onConfirm={this.handleBillingInfoSearch} disabled={searching} />
              {ListComponent}
            </Fragment>
          )
        } else {
          BodyContent = ListComponent
        }
      }
    }

    return (
      <React.Fragment>
        <ABanner {...headerContent} />
        <Container className="pad">
          <Modal isOpen={this.state.collapse} size="lg" toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>
              <Row>
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col />
                <Col>
                  <img style={{ width: '200px' }} src={AllAbordoLogo} className="img-responsive" />
                </Col>
                <Col />
              </Row>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs="2">
                  <i className="fa fa-4x mt-3 fa-user-clock" style={{ color: '#27387e' }} />
                </Col>
                <Col>
                  <Row />
                  <Row>
                    <h2>
                      Factura en proceso. Pueda tardar entre{' '}
                      <img src="https://img.icons8.com/material-outlined/24/000000/delivery-time.png" /> 24 a 48 horas en llegar a su
                      correo.
                    </h2>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col />
                <Col>
                  <button
                    type="submit"
                    onClick={() => {
                      this.toggle()
                    }}
                    className="m-btn-med-rosa "
                  >
                    OK
                  </button>
                </Col>
                <Col />
              </Row>
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.isEmailModalOpen} size="lg" toggle={this.toggle}>
            <Formsy
              ref={this.getEmailForm}
              onValidSubmit={this.onEmailSubmit}
              onValid={this.onValidFormEmail}
              onInvalid={this.onInvalidFormEmail}
            >
              <ModalHeader toggle={this.toggle}>
                <label className="bluep h6">
                  <i className="fas fa-address-book" style={{ paddingRight: 10 }} /> Datos de facturación
                </label>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col sm={12}>
                      <InputFormsy
                        name="email"
                        label="Correo electrónico:"
                        inputProps={{ placeholder: 'Escribe tu correo electrónico' }}
                        validations={{ isEmail: true }}
                        validationErrors={{ isEmail: 'El texto introducido no es un correo válido' }}
                        required
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <AButton type="submit" variant="pink" disabled={!this.state.canSubmitEmail}>
                  Generar
                </AButton>
                <AButton type="button" variant="gray" onClick={() => this.setState({ isEmailModalOpen: false })}>
                  Cancelar
                </AButton>
              </ModalFooter>
            </Formsy>
          </Modal>
          {BodyContent}
        </Container>
      </React.Fragment>
    )
  }
}

export default InvoiceLayout
