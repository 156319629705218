import React, { Component, Fragment } from 'react'
import { AButton } from 'shared/components/common'
import { EmailCaptureProps, EmailCaptureState } from './types'
import { cfdiUses } from './cfdiUses'
import { taxRegimens } from './taxRegimens'
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap'
import Formsy, { addValidationRule } from 'formsy-react'
import Container from 'reactstrap/lib/Container'
import { InputFormsy, SelectFormsy } from 'shared/components/formsy'

addValidationRule('isEnterpriseName', (_values: any, value: string) => {
  return value && /[^\|]/g.test(value)
})

export class EmailCapture extends Component<EmailCaptureProps, EmailCaptureState> {
  form: any
  state: EmailCaptureState = {}
  regimens: any = []
  toggle = () => {
    const value = !this.state.modal
    this.setState({
      modal: value
    })
  }

  getForm = (f: any) => {
    if (f) {
      this.form = f
      const { name = '', zip_code = '', tax_regimen = '', default: email = '', legal_person = '' } = this.props
      this.regimens = legal_person ? taxRegimens.filter((t: any) => (legal_person === 'fisico' ? t.fisica : t.moral)) : taxRegimens
      this.form.reset({ tax_regimen, email, zip_code: zip_code.toString(), name: this.removeAccents(name) })
    }
  }

  onSubmit = (model: any) => {
    const { onConfirm } = this.props
    if (onConfirm) {
      const name = this.removeAccents(model.name)
      onConfirm(name, model.zip_code, model.tax_regimen, model.email, model.cfdiUse, model.type)
    }
    this.toggle()
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }

  removeAccents(str: string) {
    return str
      .replace(/[áäàâ]/gi, 'a')
      .replace(/[éëèê]/gi, 'e')
      .replace(/[íïìî]/gi, 'i')
      .replace(/[óöòô]/gi, 'o')
      .replace(/[úüùû]/gi, 'u')
      .trim()
      .toUpperCase()
  }

  render() {
    const { canSubmit } = this.state
    return (
      <Fragment>
        <AButton variant="pink" onClick={this.toggle} className="font-weight-bold text-bold">
          Generar factura
        </AButton>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" centered backdrop="static">
          <Formsy ref={this.getForm} onValidSubmit={this.onSubmit} onValid={this.onValidForm} onInvalid={this.onInvalidForm}>
            <ModalHeader toggle={this.toggle}>
              <label className="bluep h6">
                <i className="fas fa-address-book" style={{ paddingRight: 10 }} /> Datos de facturación
              </label>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col sm={12} className="mb-2">
                    <InputFormsy
                      name="name"
                      label="Razón social:"
                      inputProps={{ placeholder: 'Razón social sin régimen societario', maxLength: 250, uppercase: true }}
                      validations={{ isEnterpriseName: true }}
                      validationErrors={{ isEnterpriseName: 'El texto introducido no es una razón social válida' }}
                      required
                    />
                    <small className="lil-blue">La razón social NO debe incluir el régimen societario (ej. S.A de C.V)</small>
                  </Col>
                  <Col sm={12} className="mb-2">
                    <InputFormsy
                      name="zip_code"
                      label="Código Postal:"
                      inputProps={{ placeholder: 'Código postal ', maxLength: 5 }}
                      validations={{
                        matchRegexp: /^\d{5}$/,
                        minLength: 5,
                        maxLength: 5
                      }}
                      validationErrors={{
                        matchRegexp: 'El código postal debe contener 5 dígitos',
                        minLength: 'El código postal debe contener 5 dígitos',
                        maxLength: 'El código postal debe contener 5 dígitos'
                      }}
                      required
                    />
                    <small className="lil-blue">El C.P debe coincidir con el mostrado en tu CIF (Cédula de Identificación Fiscal)</small>
                  </Col>
                  <Col sm={12} className="mb-2">
                    <SelectFormsy name="tax_regimen" label="Régimen fiscal" value={this.props.tax_regimen} required>
                      <option value="">Selecciona el régimen fiscal</option>
                      {this.regimens.map((u: any) => (
                        <option key={u.code} value={u.code}>
                          {u.description}
                        </option>
                      ))}
                    </SelectFormsy>
                  </Col>
                  <Col sm={12} className="mb-2">
                    <SelectFormsy
                      name="cfdiUse"
                      label="Uso de CFDI"
                      selectProps={{
                        className: 'custom-select'
                      }}
                      value=""
                      required
                    >
                      <option value="">Selecciona el uso de CFDI</option>
                      {cfdiUses.map(u => (
                        <option key={u.code} value={u.code}>
                          {u.description}
                        </option>
                      ))}
                    </SelectFormsy>
                  </Col>
                  <Col sm={12} className="mb-2">
                    <InputFormsy
                      name="email"
                      label="Correo electrónico:"
                      inputProps={{ placeholder: 'Escribe tu correo electrónico' }}
                      validations={{ isEmail: true }}
                      validationErrors={{ isEmail: 'El texto introducido no es un correo válido' }}
                      required
                    />
                  </Col>
                  {this.props.isParcel || this.props.isGuiapp ? (
                    <Col sm={12} className="mb-2">
                      <SelectFormsy
                        name="type"
                        label="Tipo de servicio"
                        selectProps={{
                          className: 'custom-select'
                        }}
                        value=""
                        required
                      >
                        <option value="">Selecciona tipo de servicio</option>
                        <option value="parcel">Paquetería y mensajería</option>
                      </SelectFormsy>
                    </Col>
                  ) : null}
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <AButton type="submit" variant="pink" disabled={!canSubmit}>
                Generar
              </AButton>
              <AButton type="button" variant="gray" onClick={this.toggle}>
                Cancelar
              </AButton>
            </ModalFooter>
          </Formsy>
        </Modal>
      </Fragment>
    )
  }
}

export default EmailCapture
